<template>
  <section>
    <list :type="2"></list>
  </section>
</template>

<script>
import list from './list'
export default {
  name: 'draft',
  components:{
    list
  },
  data(){

    return {

    }

  },
  methods:{

  }
}
</script>

<style scoped lang="scss">

</style>
